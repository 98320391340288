<template>
	<div class="container">
		<div class="top">
			<el-input placeholder="请输入关键词进行搜索" v-model="searchData.search"></el-input>
			<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
			<el-select v-model="searchData.deviceTypeId" placeholder="请选择设备类型" @change="changeSearchData($event,1)">
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList1" :key="item.id">
				</el-option>
			</el-select>
			<el-select v-model="searchData.deviceNameId" placeholder="请选择设备名称" @change="changeSearchData($event,2)">
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList2" :key="item.id">
				</el-option>
			</el-select>
			<el-select v-model="searchData.deviceModelId" placeholder="请选择设备型号" @change="changeSearchData($event,3)">
				<el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList3" :key="item.id">
				</el-option>
			</el-select>
			<!--
			<el-date-picker class="time" v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
				start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="change">
			</el-date-picker>
			-->
			<!--
			<el-button type="primary" icon="el-icon-plus" class="add-username" @click="add">导出报表</el-button>
			-->
		</div>
		<el-table :data="tableData" stripe style="width: 100%" :max-height="$store.state.clientHeight">
			<el-table-column prop="id" label="序号" width="180">
			</el-table-column>
			<el-table-column prop="created_at" label="操作时间" width="250">
			</el-table-column>
			<el-table-column prop="user" label="操作账号">
			</el-table-column>
			<el-table-column prop="deviceType" label="设备种类">
			</el-table-column>
			<el-table-column prop="deviceName" label="设备名称">
			</el-table-column>
			<el-table-column prop="deviceModel" label="设备型号">
			</el-table-column>
			<el-table-column prop="sn" label="设备序列号">
			</el-table-column>
			<el-table-column prop="content" label="变更内容" min-width="260">
			</el-table-column>
		</el-table>
		<!--
		<el-pagination class="footer" background layout="prev, pager, next, jumper" :total="1000">
		</el-pagination>
		-->
	</div>
</template>
<script>
	export default {
		data() {
			return {
				pickerOptions: { // 限制收货时间不让选择今天以前的
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				// 搜索处理*********
				searchData: {
					search: '',
					deviceTypeId: '',
					deviceNameId: '',
					deviceModelId: ''
				},
				DeviceTypeList1: [],
				DeviceType1: 0,
				DeviceTypeList2: [],
				DeviceType2: 0,
				DeviceTypeList3: [],
				DeviceType3: 0,
				// ***********************
				chart: '',
				time: '',
				activeName: "first",
				router: false,
				input: '',
				user_type: '',
				tableData: []
			}
		},
		created() {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
			this.getDeviceTypeList(1)
			this.time = [start, end]
			this.getChangeList()
		},
		methods: {
			// 搜素
			search() {
				this.getChangeList()
			},
			// 下拉选择框被改变
			changeSearchData(e, id) {
				console.log(e, id)
				if (id === 1) {
					this.DeviceType1 = e
					this.searchData.deviceNameId = ''
					this.searchData.deviceModelId = ''
					this.DeviceTypeList2 = []
					this.DeviceTypeList3 = []
					this.getDeviceTypeList(2, e)
				}
				if (id === 2) {
					this.DeviceType2 = e
					this.searchData.deviceModelId = ''
					this.DeviceTypeList3 = []
					this.getDeviceTypeList(3, e)
				}
				if (id === 3) {
					this.DeviceType3 = e
				}
				console.log(this.searchData)
				this.getChangeList()
			},
			// 获取设备类型列表
			getDeviceTypeList(type, pid) { // 1:类型 2:名称 3型号
				this.$http.post('device-type/list', {
					type,
					pid
				}).then(res => {
					if (res.data.code === 2000) {
						if (type == 1) {
							this.DeviceTypeList1 = res.data.data
						}
						if (type == 2) {
							this.DeviceTypeList2 = res.data.data
						}
						if (type == 3) {
							this.DeviceTypeList3 = res.data.data
						}
					}
					console.log(res)
				})
			},
			// 参数变更记录
			async getChangeList() {
				let res = await this.$http.post('record/parameter-change', this.searchData)
				if (res.data.code !== 2000) {
					console.log('异常', res.data)
					return
				}
				this.tableData = res.data.data
				this.tableData.forEach((item, index) => {
					item.id = index + 1
				})
				console.log(res)
			},
			// 计算高度
			getListHeight() {
				let Html = document.getElementsByClassName('box-card')
				if (Html.length > 0) {
					this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60

				}
			}
		},
		mounted() {
			this.getListHeight()
			window.onresize = () => {
				return (() => {
					this.getListHeight()
				})();
			}
		},
		destroyed() {
			window.onresize = null;
		}
	};
</script>

<style lang="less" scoped>
	.el-input {
		width: 240px;
		margin-right: 10px;
	}

	.el-select {
		width: 180px;
		margin-left: 20px;
	}

	.time {
		margin-left: 20px;
		width: 260px;
	}

	.container {
		display: flex;
		flex-direction: column;
	}

	.top {
		// height: 32px;
		margin-bottom: 20px;
		position: relative;
	}

	.add-username {
		position: absolute;
		right: 0;
	}

	.footer {
		display: flex;
		padding: 0;
		margin-top: 14px;
		justify-content: flex-end;
	}

	.state-item {
		width: 400px;
		height: 350px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: 14px;
		border: 1px solid #BBBBBB;
		// justify-content: flex-start;
		// align-items: flex-start;

		.item-title {

			width: 100%;
			height: 54px;
			background-color: #E1F0F9;
			color: #000000;
			font-size: 16px;
			font-family: SourceHanSansSC-bold;
			text-align: center;
			line-height: 54px;

		}

		.item-1 {
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
			text-align: center;
		}

		.item-3 {
			width: 33.333%;
			height: 40px;
			text-align: center;
			word-wrap: break-word;
			// line-height: 40px;
		}

		.item-2 {
			box-sizing: border-box;
			width: 50%;
			height: 34px;
			text-align: center;
			line-height: 34px;
			border: 1px solid rgba(240, 240, 240, 100);
		}
	}
</style>
